var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer" },
    [
      _c("div", { ref: "contentTop" }, [
        _c("div", { staticClass: "tool" }, [
          _c("div", { staticClass: "customer-tool" }, [
            _c("div"),
            _c(
              "div",
              { staticStyle: { "line-height": "40px" } },
              [
                _vm.permUtil.WbOrder.createExcelBtn()
                  ? _c(
                      "el-button",
                      {
                        staticClass: "hq-button",
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.createExcel.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v(" 报名导入 ")]
                    )
                  : _vm._e(),
                _vm.permUtil.WbOrder.learningBtn()
                  ? _c(
                      "el-button",
                      {
                        staticClass: "hq-button",
                        attrs: { type: "primary", disabled: !_vm.orderId },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeCustomerstudent.apply(
                              null,
                              arguments
                            )
                          }
                        }
                      },
                      [_vm._v(" 修改学习账号 ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    staticClass: "hq-button",
                    attrs: { type: "primary", disabled: !_vm.orderId },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.changeCustomer.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v(" 变更渠道名称 ")]
                ),
                _c(
                  "span",
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          disabled: _vm.orderIds.length > 0,
                          effect: "light",
                          content: "当前没有异常协议，无需重推",
                          placement: "bottom-end"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "inline" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "hq-button",
                                attrs: {
                                  type: "primary",
                                  disabled: !_vm.orderIds.length
                                },
                                on: { click: _vm.signClick }
                              },
                              [_vm._v("重推协议")]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          disabled: !_vm.isChange,
                          effect: "light",
                          content: _vm.changeMsg,
                          placement: "bottom-end"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "inline" } },
                          [
                            _vm.permUtil.WbOrder.orderChangeRecruit()
                              ? _c("change-modal", {
                                  attrs: {
                                    disabled: _vm.isChange || !_vm.orderId,
                                    orderTypeset: _vm.orderTypeset,
                                    method: _vm.getcustomerList,
                                    changeType: "0",
                                    infoList: Object.assign({}, _vm.infoList, {
                                      changeBeforeCrmId:
                                        _vm.teacherIdType
                                          .crmTeacherRecruitUserId
                                    }),
                                    teacherList: _vm.teacherList,
                                    changeBeforeName:
                                      _vm.teacherType.teacherRecruitName,
                                    changeBeforeNcId:
                                      _vm.teacherIdType.teacherRecruitId,
                                    title: "招生老师"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          disabled: !_vm.isChange,
                          effect: "light",
                          content: _vm.changeMsg,
                          placement: "bottom-end"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "inline" } },
                          [
                            _vm.permUtil.WbOrder.orderChangeSales()
                              ? _c("change-modal", {
                                  attrs: {
                                    disabled: !_vm.orderId || _vm.isChange,
                                    orderTypeset: _vm.orderTypeset,
                                    method: _vm.getcustomerList,
                                    changeType: "1",
                                    infoList: Object.assign({}, _vm.infoList, {
                                      changeBeforeCrmId:
                                        _vm.teacherIdType.crmTeacherSalesUserId
                                    }),
                                    teacherList: _vm.saleTeacher,
                                    changeBeforeName:
                                      _vm.teacherType.teacherSalesName,
                                    changeBeforeNcId:
                                      _vm.teacherIdType.teacherSalesId,
                                    title: "销售"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          disabled: !_vm.isChange,
                          effect: "light",
                          content: _vm.changeMsg,
                          placement: "bottom-end"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "inline" } },
                          [
                            _vm.permUtil.WbOrder.orderChangeTransfer()
                              ? _c("change-modalteach", {
                                  attrs: {
                                    disabled: !_vm.orderId || _vm.isChange,
                                    orderTypeset: _vm.orderTypeset,
                                    method: _vm.getcustomerList,
                                    changeType: "2",
                                    infoList: Object.assign({}, _vm.infoList, {
                                      changeBeforeCrmId:
                                        _vm.teacherIdType
                                          .crmTeacherRecommandUserId
                                    }),
                                    teacherList: _vm.saleTransfer,
                                    changeBeforeName:
                                      _vm.teacherType.teacherRecommandName,
                                    changeBeforeNcId:
                                      _vm.teacherIdType.teacherRecommandId,
                                    title: "转介绍老师"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm.permUtil.WbOrder.orderExport()
                  ? _c("Export", {
                      attrs: {
                        pageParam: _vm.pageParam,
                        total: _vm.total,
                        searchParam: _vm.searchParam
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          {
            staticStyle: { width: "94%", margin: "0 auto" },
            attrs: { gutter: 10 }
          },
          [
            _vm.searchParamList.length
              ? _c("Search", {
                  attrs: {
                    searchAllParam: _vm.searchAllParam,
                    searchParamList: _vm.searchParamList,
                    searchParamFromApi: _vm.searchParamFromApi,
                    code: "orderList"
                  },
                  on: {
                    search: _vm.search,
                    reset: _vm.reset,
                    searchMore: _vm.searchMore,
                    searchList: _vm.searchList
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "line" })
      ]),
      _c(
        "el-row",
        { staticStyle: { width: "94%", margin: "0 auto" } },
        [
          _c(
            "el-col",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "highlight-current-row": "",
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    border: "",
                    stripe: "",
                    height: _vm.tableHeight,
                    size: "mini",
                    "element-loading-text": "拼命加载中",
                    "row-style": { height: "20px" },
                    "cell-style": { padding: "0px" },
                    "header-cell-style": { background: "#e5f2ff" }
                  },
                  on: {
                    "row-click": _vm.clickRow,
                    "selection-change": _vm.selectionRow
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      align: "center",
                      prop: "checked",
                      label: "选择"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "订单号",
                      prop: "orderNo",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "学员姓名",
                      prop: "userName",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "学员学历",
                      prop: "educationName",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "报读商品",
                      prop: "goodsName",
                      width: "200"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#409EFF",
                                  cursor: "pointer"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toDetaiPage(scope.row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(scope.row.goodsName))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "手机",
                      prop: "mobilePhone",
                      width: "150"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            !!scope.row.mobilePhone
                              ? _c(
                                  "div",
                                  { staticClass: "phone" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.mobilePhone))
                                    ]),
                                    _vm.permUtil.WbCusotmer.customerSeePhone()
                                      ? _c("el-button", {
                                          attrs: {
                                            type: "text",
                                            icon: "el-icon-document-copy"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.seeShowClick(scope.row)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "产品线",
                      prop: "productName",
                      width: "140"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "学历班型",
                      prop: "isClassTypeName",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "商品价格",
                      prop: "totalMoney",
                      width: "140"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (scope.row.totalMoney &&
                                    scope.row.totalMoney.toFixed(2)) ||
                                    "0.00"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "应收金额",
                      prop: "payPrice",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (scope.row.payPrice &&
                                    scope.row.payPrice.toFixed(2)) ||
                                    "0.00"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "已收金额",
                      prop: "amountReceived",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (scope.row.amountReceived &&
                                    scope.row.amountReceived.toFixed(2)) ||
                                    "0.00"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "未回款金额",
                      width: "120",
                      prop: "collectedMoney"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (scope.row.collectedMoney &&
                                    scope.row.collectedMoney.toFixed(2)) ||
                                    "0.00"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "支付方式",
                      prop: "payModeName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "订单状态",
                      prop: "orderStatusName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "协议状态",
                      prop: "signContractStatusName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "学习状态",
                      prop: "studyStatusName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "销售老师",
                      prop: "teacherSalesName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "招生老师",
                      prop: "teacherRecruitName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "是否收齐款",
                      prop: "allPay",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.allPay == 1 ? "是" : "否") +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "转介绍",
                      prop: "isRecommand",
                      width: "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.isRecommand == 1 ? "是" : "否"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "转介绍老师",
                      prop: "teacherRecommandName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "商机赛道",
                      prop: "projectCodeName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "首次成交时间",
                      prop: "firstDealTime",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "成交时间",
                      prop: "createTime",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "报名校区",
                      prop: "subscribeSchoolName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "上课校区",
                      prop: "signSchoolName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "渠道名称",
                      prop: "channelsTypeName",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "身份证", prop: "idCard", width: "200" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      prop: "",
                      width: "100",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.toDetaiPage(scope.row)
                                  }
                                }
                              },
                              [_vm._v("订单详情")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "page_test_bottom" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.pageParam.pageNum,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.pageParam.pageSize,
                      total: _vm.total
                    },
                    on: {
                      "size-change": _vm.sizeChange,
                      "current-change": _vm.currentChange
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.excelShow, title: "报名导入", width: "400" },
          on: { "on-cancel": _vm.cancelexcel }
        },
        [
          _c(
            "div",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticStyle: { "margin-right": "10px" } }, [
                  _vm._v("模板")
                ]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.publicPath + "static/resource/批量商机报名.xlsx"
                    }
                  },
                  [_vm._v("请点击下载模板")]
                )
              ]),
              _c(
                "el-col",
                { staticStyle: { position: "relative" }, attrs: { span: 12 } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.excelFile.file,
                        expression: "excelFile.file"
                      }
                    ],
                    staticStyle: { display: "none" },
                    domProps: { value: _vm.excelFile.file },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.excelFile, "file", $event.target.value)
                      }
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "upload upload-btn",
                      staticStyle: { position: "relative" },
                      attrs: { type: "primary" }
                    },
                    [
                      _vm._v(" 选择文件 "),
                      _c("input", {
                        ref: "file",
                        staticClass: "change",
                        staticStyle: {
                          left: "0",
                          top: "0",
                          opacity: "0",
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          "font-size": "0"
                        },
                        attrs: { type: "file" },
                        on: {
                          change: function($event) {
                            return _vm.getFile()
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm.excelFile.file.name
                ? _c("el-col", { attrs: { span: 24 } }, [
                    _vm._v(" 文件   " + _vm._s(_vm.excelFile.file.name) + " ")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelexcel } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.excelLoading, type: "primary" },
                  on: { click: _vm.updateExcel }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "HqModal",
        {
          attrs: {
            visible: _vm.changestudentShow,
            title: "修改学习账号",
            width: "400"
          },
          on: { "on-cancel": _vm.cancelstudent }
        },
        [
          _c(
            "el-form",
            { ref: "formstudent", attrs: { "lazy-validation": "" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "12px" },
                      attrs: { span: 24 }
                    },
                    [
                      _c("span", [_vm._v("订单编号: ")]),
                      _c("span", [_vm._v(_vm._s(_vm.formstudent.orderNo))])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "12px" },
                      attrs: { span: 24 }
                    },
                    [
                      _c("span", [_vm._v("学习账户: ")]),
                      _c("span", [_vm._v(_vm._s(_vm.formstudent.oldPhone))])
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "12px" },
                          attrs: { prop: "newPhone" }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "修改为",
                              "hide-details": "auto",
                              outlined: "",
                              dense: true,
                              height: 32,
                              clearable: true
                            },
                            model: {
                              value: _vm.formstudent.newPhone,
                              callback: function($$v) {
                                _vm.$set(_vm.formstudent, "newPhone", $$v)
                              },
                              expression: "formstudent.newPhone"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.showDisabled
                          },
                          on: { click: _vm.verCodeClick }
                        },
                        [_vm._v(_vm._s(_vm.phoneTipMsg))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "12px" },
                          attrs: { prop: "reason" }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "验证码",
                              "hide-details": "auto",
                              outlined: "",
                              dense: true,
                              height: 32,
                              clearable: true
                            },
                            model: {
                              value: _vm.formstudent.code,
                              callback: function($$v) {
                                _vm.$set(_vm.formstudent, "code", $$v)
                              },
                              expression: "formstudent.code"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("span", [
                      _vm._v(
                        "为了确保修改数据的真实性，学员将会收到一条短信验证码，招生老师跟学员沟通获取。"
                      )
                    ]),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        "短信模版：【恒企教育】验证码0066，您的老师正在为您更新学习账号， 旧的学习账号（15820334365）的学习记录将会被新的手机号所继承， 请知晓，如确认修改请将验证码发送给帮您修改的老师！"
                      )
                    ])
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "12px" },
                          attrs: { prop: "reason" }
                        },
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              height: 80,
                              name: "input-7-4",
                              label: "理由",
                              value: ""
                            },
                            model: {
                              value: _vm.formstudent.reason,
                              callback: function($$v) {
                                _vm.$set(_vm.formstudent, "reason", $$v)
                              },
                              expression: "formstudent.reason"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("span", [
                      _vm._v(
                        "学员如果当前学习账号在使用当中，可引导学员到app学习中心中自行修改"
                      )
                    ]),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        "本功能适用场景：仅限学员更换了手机号码之后提起的账号变更"
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelstudent } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingsave },
                  on: { click: _vm.changeSavestaudent }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.changeShowzx, title: "请知晓", width: "400" },
          on: { "on-cancel": _vm.cancelstudentdelete }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", [_vm._v("修改学习账号须知 ")])
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", [
                  _vm._v(
                    "1、原学员账号的学习记录和开课记录将保留，如果新的学习账号已在网校 注册过，则新的学习账号上的学习记录将会被旧的学习账号的学习记录和开 课记录所覆盖 "
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", [_vm._v("2、订单上的手机号会被更新为新的学习账号")])
              ]),
              _c("br"),
              _c("div", {
                staticStyle: {
                  display: "inline-block",
                  "margin-top": "20px",
                  width: "20px",
                  height: "1px"
                }
              }),
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "span",
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.sign,
                          callback: function($$v) {
                            _vm.sign = $$v
                          },
                          expression: "sign"
                        }
                      },
                      [
                        _c("el-checkbox", { attrs: { label: "", value: 1 } }, [
                          _vm._v(
                            "我已知晓以上内容，修改学习账号如果导致的后续 "
                          ),
                          _c("br"),
                          _vm._v(
                            " 学员学习记录和开课被删除， 我愿为此行为承担责任! "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelstudentdelete } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingsave },
                  on: { click: _vm.Savestaudent }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "HqModal",
        {
          attrs: {
            visible: _vm.changeShow,
            title: "变更渠道名称",
            width: "400"
          },
          on: { "on-cancel": _vm.cancel }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "lazy-validation": "" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "12px" },
                          attrs: { prop: "reason" }
                        },
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              height: 80,
                              name: "input-7-4",
                              label: "变更理由",
                              value: ""
                            },
                            model: {
                              value: _vm.reason,
                              callback: function($$v) {
                                _vm.reason = $$v
                              },
                              expression: "reason"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: { span: 24 }
                    },
                    [
                      _c("span", [_vm._v("原渠道名称:")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.recruitChanne.channelsTypeName))
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "crmChannelsTypeId" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.recruitChannelsselect,
                              "menu-props": { bottom: true, offsetY: true },
                              label: "渠道名称",
                              "item-text": "name",
                              "item-value": "id",
                              outlined: "",
                              dense: true,
                              height: 32,
                              clearable: true,
                              "clear-icon": "$clear",
                              "hide-details": "auto"
                            },
                            on: { change: _vm.Changeselect },
                            model: {
                              value: _vm.form.crmChannelsTypeId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "crmChannelsTypeId", $$v)
                              },
                              expression: "form.crmChannelsTypeId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.form.crmChannelsTypeId === 1723 ||
                  _vm.form.promotionForm == ""
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "flowPartner" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.partnerList,
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "流量合伙人",
                                  "item-text": "flowPartner",
                                  "item-value": "flowPartner",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  "clear-icon": "$clear",
                                  "hide-details": "auto"
                                },
                                on: { change: _vm.partnerClick },
                                model: {
                                  value: _vm.form.flowPartner,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "flowPartner", $$v)
                                  },
                                  expression: "form.flowPartner"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.crmChannelsTypeId === 1723 ||
                  _vm.form.promotionForm == ""
                    ? _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "promotionForm" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.promotionFormselect,
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "促单形式",
                                  "item-text": "name",
                                  "item-value": "id",
                                  outlined: "",
                                  dense: true,
                                  height: 32,
                                  clearable: true,
                                  "clear-icon": "$clear",
                                  "hide-details": "auto"
                                },
                                on: { change: _vm.promotionFormChange },
                                model: {
                                  value: _vm.form.promotionForm,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "promotionForm", $$v)
                                  },
                                  expression: "form.promotionForm"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingsave },
                  on: { click: _vm.changeSave }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }