<!--
 * @Description: 订单管理
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-22 16:36:56
 * @LastEditors: crm
 * @LastEditTime: 2024-01-19 11:19:44
-->
<template>
  <div class="customer">
    <div ref="contentTop">
      <div class="tool">
        <div class="customer-tool">
            <div></div>
            <div style="line-height: 40px">
            <!-- 报名导入 商机excel导入报名做单 -->
            <el-button
              type="primary"
              class="hq-button"
              v-if="permUtil.WbOrder.createExcelBtn()"
              @click.stop="createExcel"
              >
                报名导入
            </el-button>
              <!-- 修改学习账号 -->
              <el-button
              type="primary"
              class="hq-button"
              :disabled="!orderId"
              v-if="permUtil.WbOrder.learningBtn()"
              @click.stop="changeCustomerstudent"
              >
                修改学习账号
            </el-button>
              <!-- 变更渠道名称 -->
              <el-button
              type="primary"
              class="hq-button"
              :disabled="!orderId"
              @click.stop="changeCustomer"
              >
                变更渠道名称
            </el-button>
              <span>
                <!-- 批量重推协议 -->
                <el-tooltip :disabled="orderIds.length > 0" class="item" effect="light" content="当前没有异常协议，无需重推" placement="bottom-end">
                  <div style="display: inline">
                    <el-button class="hq-button" type="primary" :disabled="!orderIds.length" @click="signClick">重推协议</el-button>
                  </div>
                </el-tooltip>
                <!-- 招生变更 -->
                <el-tooltip :disabled="!isChange" class="item" effect="light" :content="changeMsg" placement="bottom-end">
                  <div style="display: inline">
                    <change-modal :disabled="isChange || !orderId" :orderTypeset="orderTypeset" v-if="permUtil.WbOrder.orderChangeRecruit()" :method="getcustomerList" changeType="0" :infoList="{...infoList, ...{changeBeforeCrmId: teacherIdType.crmTeacherRecruitUserId}}" :teacherList="teacherList" :changeBeforeName="teacherType.teacherRecruitName" :changeBeforeNcId="teacherIdType.teacherRecruitId" title="招生老师"></change-modal>
                  </div>
                </el-tooltip>
                <!-- 变更归属销售 -->
                <el-tooltip :disabled="!isChange" class="item" effect="light" :content="changeMsg" placement="bottom-end">
                  <div style="display: inline">
                    <change-modal :disabled="!orderId || isChange" :orderTypeset="orderTypeset" v-if="permUtil.WbOrder.orderChangeSales()" :method="getcustomerList" changeType="1" :infoList="{...infoList, ...{changeBeforeCrmId: teacherIdType.crmTeacherSalesUserId}}" :teacherList="saleTeacher" :changeBeforeName="teacherType.teacherSalesName" :changeBeforeNcId="teacherIdType.teacherSalesId" title="销售"></change-modal>
                  </div>
                </el-tooltip>
                <!-- 变更转介绍  -->
                <el-tooltip :disabled="!isChange" class="item" effect="light" :content="changeMsg" placement="bottom-end">
                  <div style="display: inline">
                    <!-- <change-modal :disabled="!orderId || isChange" :orderTypeset="orderTypeset" v-if="permUtil.WbOrder.orderChangeTransfer()" :method="getcustomerList" changeType="2" :infoList="{...infoList, ...{changeBeforeCrmId: teacherIdType.crmTeacherRecommandUserId}}" :teacherList="saleTransfer" :changeBeforeName="teacherType.teacherRecommandName" :changeBeforeNcId="teacherIdType.teacherRecommandId" title="转介绍老师"></change-modal> -->
                    <change-modalteach :disabled="!orderId || isChange" :orderTypeset="orderTypeset" v-if="permUtil.WbOrder.orderChangeTransfer()" :method="getcustomerList" changeType="2" :infoList="{...infoList, ...{changeBeforeCrmId: teacherIdType.crmTeacherRecommandUserId}}" :teacherList="saleTransfer" :changeBeforeName="teacherType.teacherRecommandName" :changeBeforeNcId="teacherIdType.teacherRecommandId" title="转介绍老师"></change-modalteach>
                  </div>
                </el-tooltip>
              </span>
              <Export v-if="permUtil.WbOrder.orderExport()" :pageParam="pageParam" :total="total" :searchParam="searchParam" />
            </div>
        </div>
      </div>
      <div
        :gutter="10"
        style="width: 94%; margin: 0 auto"
      >
        <Search
          v-if="searchParamList.length"
          :searchAllParam="searchAllParam"
          @search="search"
          @reset="reset"
          @searchMore="searchMore"
          @searchList="searchList"
          :searchParamList="searchParamList"
          :searchParamFromApi="searchParamFromApi"
          code="orderList"
        />
      </div>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          :height="tableHeight"
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @row-click="clickRow"
          @selection-change="selectionRow"
        >
          <el-table-column type="selection" width="55" align="center" prop="checked" label="选择">
            <!-- <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked" @change="changeCurrentRow( $event, scope.$index, scope.row )" ></el-checkbox>
            </template> -->
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="订单号"
            prop="orderNo"
            width="180"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学员姓名"
            prop="userName"
            width="150"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学员学历"
            prop="educationName"
            width="150"
            
          >
          </el-table-column>
          <el-table-column 
            :show-overflow-tooltip="true"
            label="报读商品"
            prop="goodsName"
            width="200"
          >
            <template slot-scope="scope">
              <span style="color: #409EFF;cursor: pointer;" @click="toDetaiPage(scope.row)">{{ scope.row.goodsName }}</span>
            </template>
          </el-table-column>
          <el-table-column 
            :show-overflow-tooltip="true"
            label="手机"
            prop="mobilePhone"
            width="150"
          >
            <template slot-scope="scope">
              <div class="phone" v-if="!!scope.row.mobilePhone">
                <span>{{ scope.row.mobilePhone }}</span>
                <el-button v-if="permUtil.WbCusotmer.customerSeePhone()" @click.stop="seeShowClick(scope.row)" type="text" icon="el-icon-document-copy"></el-button>
                <!-- <el-button @click="autoCall(scope.row)" type="text" icon="el-icon-phone"></el-button> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="产品线"
            prop="productName"
            width="140"
          ></el-table-column>
          <!-- <el-table-column :show-overflow-tooltip="true"
            label="商品类型"
            prop="goodsTypeName"
            width="140"
            
            sortable="custom"
          ></el-table-column> -->
          <el-table-column :show-overflow-tooltip="true"
            label="学历班型"
            prop="isClassTypeName"
            width="150"
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="商品价格"
            prop="totalMoney"
            width="140"
          >
            <template slot-scope="scope">
              {{ scope.row.totalMoney && scope.row.totalMoney.toFixed(2) || '0.00' }}
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="应收金额"
            prop="payPrice"
            width="120"
          >
            <template slot-scope="scope">
              {{ scope.row.payPrice && scope.row.payPrice.toFixed(2) || '0.00' }}
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="已收金额"
            prop="amountReceived"
            width="120"
          > 
            <template slot-scope="scope">
              {{ scope.row.amountReceived && scope.row.amountReceived.toFixed(2) || '0.00' }}
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="未回款金额"
            width="120"
            prop="collectedMoney"
          >
            <template slot-scope="scope">
              {{ scope.row.collectedMoney && scope.row.collectedMoney.toFixed(2) || '0.00' }}
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="支付方式"
            prop="payModeName"
            width="120"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="订单状态"
            prop="orderStatusName"
            width="120"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="协议状态"
            prop="signContractStatusName"
            width="120"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学习状态"
            prop="studyStatusName"
            width="120"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="销售老师"
            prop="teacherSalesName"
            width="120"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="招生老师"
            prop="teacherRecruitName"
            width="120"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="是否收齐款"
            prop="allPay"
            width="120"
          >
            <template slot-scope="scope">
              {{scope.row.allPay == 1 ? '是': '否'}}
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="转介绍"
            prop="isRecommand"
            width="120"
            
          >
             <template slot-scope="scope">
              {{scope.row.isRecommand == 1 ? '是': '否'}}
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="转介绍老师"
            prop="teacherRecommandName"
            width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="商机赛道"
            prop="projectCodeName"
            width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="首次成交时间"
            prop="firstDealTime"
            width="150"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="成交时间"
            prop="createTime"
            width="150"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="报名校区"
            prop="subscribeSchoolName"
            width="120"
            
          ></el-table-column>  
          <el-table-column :show-overflow-tooltip="true"
            label="上课校区"
            prop="signSchoolName"
            width="120"
            
          ></el-table-column>
          
          <el-table-column
            label="渠道名称"
            prop="channelsTypeName"
            width="120"
          ></el-table-column>
          <el-table-column
            label="身份证"
            prop="idCard"
            width="200"
          ></el-table-column>
          <el-table-column
            label="操作"
            prop=""
            width="100"
            
            fixed="right"
          >
            <template slot-scope="scope">
                <el-button type="text"  @click="toDetaiPage(scope.row)">订单详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <!-- 报名导入 -->
    <HqModal :visible="excelShow" title="报名导入" width="400" @on-cancel="cancelexcel">
      <div>
        <el-col :span="12">
          <span style="margin-right:10px">模板</span>
            <a :href='`${publicPath}static/resource/批量商机报名.xlsx`'>请点击下载模板</a>
        </el-col>
        <el-col :span="12" style="position: relative;">
          <input v-model="excelFile.file" style="display: none" />
          <el-button class="upload upload-btn" type="primary" style="position: relative;">
            选择文件
            <input class="change" type="file"  ref="file"
             style="left: 0;top:0;opacity: 0; position: absolute;width: 100%;height: 100%;cursor: pointer;font-size: 0;"
             @change="getFile()">
          </el-button>
        </el-col>
        <el-col v-if="excelFile.file.name" :span="24">
          文件&nbsp;&nbsp; {{ excelFile.file.name }}
        </el-col>
      </div>
      <div slot="footer">
        <el-button @click="cancelexcel">取消</el-button>
        <el-button @click="updateExcel" :loading="excelLoading" type="primary">确认</el-button>
      </div>
    </HqModal>
    
    <!--修改学习账号  -->
    <HqModal :visible="changestudentShow" title="修改学习账号" width="400" @on-cancel="cancelstudent">
      <el-form ref="formstudent" lazy-validation>
        <el-row :gutter="10">
          <el-col :span="24" style="margin-top: 12px">
            <span>订单编号: </span>
            <span>{{formstudent.orderNo}}</span>
          </el-col>
          <el-col :span="24" style="margin-top: 12px">
            <span>学习账户: </span>
            <span>{{formstudent.oldPhone}}</span>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="newPhone" style="margin-top: 12px">
              <v-text-field
                v-model="formstudent.newPhone"
                label="修改为"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-form-item>
            <el-button  @click="verCodeClick" type="primary" :disabled="showDisabled">{{phoneTipMsg}}</el-button>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="reason" style="margin-top: 12px">
              <v-text-field
                v-model="formstudent.code"
                label="验证码"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
              ></v-text-field>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <span>为了确保修改数据的真实性，学员将会收到一条短信验证码，招生老师跟学员沟通获取。</span>
            <br />
            <span>短信模版：【恒企教育】验证码0066，您的老师正在为您更新学习账号，
              旧的学习账号（15820334365）的学习记录将会被新的手机号所继承，
              请知晓，如确认修改请将验证码发送给帮您修改的老师！</span>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="reason" style="margin-top: 12px">
                <v-textarea
                v-model="formstudent.reason"
                outlined
                :height="80"
                name="input-7-4"
                label="理由"
                value=""
              ></v-textarea>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <span>学员如果当前学习账号在使用当中，可引导学员到app学习中心中自行修改</span>
            <br />
            <span>本功能适用场景：仅限学员更换了手机号码之后提起的账号变更</span>
          </el-col>
        </el-row>
      </el-form>
       <div slot="footer">
        <el-button @click="cancelstudent">取消</el-button>
        <el-button @click="changeSavestaudent" type="primary" :loading="loadingsave">确认</el-button>
      </div>
    </HqModal>
    <!--请知晓  -->
     <HqModal :visible="changeShowzx" title="请知晓" width="400" @on-cancel="cancelstudentdelete">
       <el-row :gutter="10">
         <el-col :span="24">
            <span>修改学习账号须知 </span>
         </el-col>
         <el-col :span="24">
            <span>1、原学员账号的学习记录和开课记录将保留，如果新的学习账号已在网校
              注册过，则新的学习账号上的学习记录将会被旧的学习账号的学习记录和开
              课记录所覆盖
            </span>
         </el-col>
         <el-col :span="24">
            <span>2、订单上的手机号会被更新为新的学习账号</span>
         </el-col>
         <br />
         <div style="display: inline-block;margin-top:20px;width: 20px;height:1px;"></div>
         <el-col :span="24">
            <span>
              <el-checkbox-group v-model="sign">
                <el-checkbox label="" :value="1">我已知晓以上内容，修改学习账号如果导致的后续
                  <br />
                  学员学习记录和开课被删除，
                  我愿为此行为承担责任!
                  </el-checkbox>
              </el-checkbox-group>
            </span>
         </el-col>
       </el-row>
         <div slot="footer">
            <el-button @click="cancelstudentdelete">取消</el-button>
            <el-button @click="Savestaudent" type="primary" :loading="loadingsave">确认</el-button>
          </div>
     </HqModal>
    <!--变更渠道名称  -->
    <HqModal :visible="changeShow" title="变更渠道名称" width="400" @on-cancel="cancel">
      <el-form ref="form" lazy-validation>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item prop="reason" style="margin-top: 12px">
                <v-textarea
                v-model="reason"
                outlined
                :height="80"
                name="input-7-4"
                label="变更理由"
                value=""
              ></v-textarea>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="margin-bottom: 20px">
            <span>原渠道名称:</span>
            <span>{{recruitChanne.channelsTypeName}}</span>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="crmChannelsTypeId">
                <v-autocomplete
                  v-model="form.crmChannelsTypeId"
                  :items="recruitChannelsselect"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="渠道名称"
                  item-text="name"
                  item-value="id"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  @change="Changeselect"
                ></v-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.crmChannelsTypeId === 1723 ||form.promotionForm == ''">
            <el-form-item prop="flowPartner">
                  <v-autocomplete
                    v-model="form.flowPartner"
                    :items="partnerList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="流量合伙人"
                    item-text="flowPartner"
                    item-value="flowPartner"
                    outlined
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    @change="partnerClick"
                  ></v-autocomplete>
                </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.crmChannelsTypeId === 1723||form.promotionForm == ''">
            <el-form-item prop="promotionForm">
              <v-select
                v-model="form.promotionForm"
                :items="promotionFormselect"
                :menu-props="{ bottom: true, offsetY: true }"
                label="促单形式"
                item-text="name"
                item-value="id"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                clear-icon="$clear"
                hide-details="auto"
                @change="promotionFormChange"
              ></v-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="changeSave" type="primary" :loading="loadingsave">确认</el-button>
      </div>
    </HqModal>
  </div>
</template>
<script>
import Search from "components/search";
import Export from "./export";
import { 
  reqworkbenchDeptTree,
  getRecruitSpinner,
  getUserCustomQuery,
  getResTeachList,
  getTyUsertList,
  getDeptList,
  getRecommentUserList,
  getFindUserExtendAll,
  getNcTeacherList,
  getRecruitName,
  getflowPartnerList,
  getorderName,
  getOrderSave,
  GetuserExtend,
  sendVerifyCode,
  getverifyCode,
  updateLearningPhone
} from "api/public";
import { getTransactionInfoByOrderId, doOperationSave, doSeeorderPhone, getJudgetLookContack } from "api/customer";
import { getOrderListPage, getChangeOperation, createOrderByExcel } from "api/order/change";
import { mapGetters } from "vuex";
import ChangeModal from "../change/components/change-modal";
import ChangeModalteach from "../change/components/change-modalteach";


export default {
  name: "orderList",
  components: {
    Search,
    Export,
    ChangeModal,
    ChangeModalteach
  },
  data() {
    return {
      sign: 0,
      excelShow:false,
      excelLoading:false,
      changestudentShow: false,
      showDisabled: false,
      changeShowzx: false,
      formstudent: {
        newPhone: '',
        orderNo: '',
        userId: ''
      },
      loadingsave: false,
      promotionFormselect: [
        {id:'招生协助',name:'招生协助'}, 
        {id:'合伙人自主', name:'合伙人自主'}],
      reason: '',
      tableData: [],
      listLoading: false,
      searchAllParam: [
        { name: "name", label: "学员姓名", type: "input", value: ""},
        { name: "mobile", label: "手机", type: "input", value: "" },
        { name: "goodsName", label: "报读商品", type: "input", value: "" },
        { name: "customerId", label: "客户编号", type: "input", value: "" },
        {
          name: "orderStatus",
          label: "订单状态",
          type: "allSelect",
          option: [
            {
              id: 11,
              name: '待支付'
            }, 
            // {
            //   id: 14,
            //   name: '订单取消'
            // }, 
            {
              id: 18,
              name: '待确认'
            }, 
            {
              id: 19,
              name: '订单完成'
            }, 
            {
              id: 20,
              name: '订单关闭'
            }
          ],
          value: "",
          text: "name", //label
          id: "id"   //value
        },
        {
          name: "dealSaleDeptId",
          label: "成交销售部门",
          type: "multiTree",
          option: [],
          value: "",
        },
        { 
          name: "createTime", 
          label: "成交时间", 
          type: "date", 
          value: [], 
          ref: "deal", 
          show: false 
        },
        {
          name: "teacherSalesCrmId",
          label: "销售老师",
          type: "select",
          option: [],
          value: "",
          text: "userName", //label
          id: "userId"   //value
        },
        {
          name: "teacherRecruitCrmId",
          label: "招生老师",
          type: "select",
          option: [],
          value: "",
         text: "userName", //label
          id: "userId"   //value
        },
        {
          name: "isRecommand",
          label: "转介绍",
          type: "select",
          option: [{
            id: 1,
            name: '是'
          }, {
            id: 0,
            name: '否'
          }],
          value: "",
          text: "name", //label
          id: "id"   //value
        },
        {
          name: "teacherRecommandNCid",
          label: "转介绍老师",
          type: "select",
          option: [],
          value: "",
          text: "nickName", //label
          id: "ncId"   //value
        }, 
        {
         name: "contractSignList",
          label: "协议状态",
          type: "dictMult",
          code: "AGREEMENT_STATUS",
          value: "",
        },
        {
          name: "allPay",
          label: "收齐款",
          type: "select",
          option: [{
            id: 1,
            name: '是'
          }, {
            id: 0,
            name: '否'
          }],
          value: "",
          text: "name", //label
          id: "id"   //value
        },
        {
          name: "saleSourceList",
          label: "订单来源",
           type: "dictMult",
          code: "SCHOOL_ID",
          value: "",
        },
        { name: "orderNo", label: "订单号", type: "input", value: ""},
        {
          name: "productId",
          label: "产品线",
          type: "allSelect",
          option: [],
          value: "",
          text: "fullName", //label
          id: "id"   //value
        },
        {
          name: "recruitChannels",
          label: "渠道名称",
          type: "allSelect",
          option: [],
          value: "",
          text: "name", //label
          id: "id"   //value
        },
        
        {
          name: "projectCode",
          label: "商机赛道",
           type: "dict",
          code: "BIZ_TYPE",
          value: "",
        },
        {
          name: "registrationTypeList",
          label: "报名类型",
          type: "allSelect",
          option: [{
            id: 1,
            name: '新增报名'
          }, {
            id: 2,
            name: '升班报名'
          }, {
            id: 3,
            name: '转班报名'
          }],
          value: "",
          text: "name", //label
          id: "id"   //value
        },
        {
          name: "dealDeptIdList",
          label: "成交校区",
          type: "multiTree",
          option: [],
          value: "",
        },
        { name: "url", label: "来源URL", type: "input", value: ""},
        {
          name: "studyStatus",
          label: "学习状态",
          type: "allSelect",
          option: [
            {
              id: -1,
              name: '无'
            },
            {
              id: 0,
              name: '在读'
            },
            {
              id: 3,
              name: '结业'
            },
            {
              id: 4,
              name: '退学'
            }
          ],
          value: "",
          text: "name", //label
          id: "id"   //value
        },
        {
          name: "isClassType",
          label: "学历班型",
          type: "dict",
          code: "IS_CLASS_TYPE",
          value: "",
        },
        {
          name: "education",
          label: "学员学历",
          type: "dictMult",
          code: "EDUCATION_TYPE",
          value: "",
        },
        { name: "idCard", label: "身份证", type: "input", value: ""},
        { 
          name: "firstDealTime", 
          label: "首次成交时间", 
          type: "date", 
          value: [], 
          ref: "firstdeal", 
          show: false 
        },
        {
          name: "payModeList",
          label: "支付方式",
          type: "allSelect",
          option: [],
          value: "",
          text: "name", //label
          id: "payMode"   //value
        },
      ],
      searchParamList: [],
      defaultParamList: ["name", "mobile"],
      searchParamFromApi:[],
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
        sortItems: [
          {
            asc: false,
            column: "createTime",
          },
        ],
      },
      // 老师下拉数据
      userList: [],
      searchParam: {},
      // 页面是否有滚动条
      isScroll: true,
      topHeight: 0,
      // 招生老师
      teacherList: [],
      // 销售老师
      saleTeacher: [],
      // 转介绍老师
      saleTransfer: [],
      // 变更条件
      infoList: {},
      teacherType: {},
      teacherIdType: {},
      orderId: "",
      isChange: false,
      changeMsg: "",
      orderIds: [],
      recruitChannelsselect: [],
      recruitChanne: {},
      currList: {},
      changeShow: false,
      form: {
        promotionForm: ''
      },
      partnerList: [],
      orderTypeset: '',
      //验证码
      smsSendStatus: false,
      phoneCodeStatus: false,
      phoneTipMsg: "获取验证码",
      smsCodeTimer: null,
      smsCodeInterval: 60,
      publicPath: process.env.BASE_URL,
      excelFile:{
        file: "",
      },
    };  
  },
  computed: {
    ...mapGetters(["loginUser"]),
    getTrackType() {
      return this.loginUser.trackType === 1 || this.loginUser.trackType === 3
        ? "FOLLOW_STATUS_ACCOUNT"
        : "FOLLOW_STATUS_SELF_EXAM";
    },
    tableHeight() {
      return window.outerHeight - this.topHeight - 215 + 'px'
    }
  },
  mounted() {
    this._getSearchAll()
    this.getSearchList();
    this.getcustomerList();
  },
  methods: {
    getFile () {
      this.excelFile.file = this.$refs.file.files[0]
    },
    // 获取验证码
    async verCodeClick() {
      let stuMobile =   this.formstudent.newPhone || "";
      if ( stuMobile.length < 11 || !stuMobile.startsWith("1")) {
        return this.$message.error("手机账号格式不正确")
      }
      if(stuMobile == this.formstudent.oldPhone) {
        return this.$message.error("新的学习账号不能与旧账号相同！")
      }
      this.smsSendStatus = true;
      //发送验证码
      let params = {
        oldNumber: this.formstudent.oldPhone,
        phoneNumber: this.formstudent.newPhone
      }
      await sendVerifyCode(params).then(res => {
        if (res.code !== 500) {
          this.smsSendStatus = false;
          //手机验证码状态
          this.phoneCodeStatus = true
          //多少秒之后重发
          this.phoneTipMsg = this.smsCodeInterval + "秒后重发"
          this.showDisabled = true
          //定时器
          if( this.smsCodeInterval ){
             this.clearSmsTimer();
          }
          this.smsCodeTimer = setInterval(() => {
            if (this.smsCodeInterval <= 1) {
              this.initPhoneTipMsg();
              return
            }
            this.smsCodeInterval--
            this.phoneTipMsg = this.smsCodeInterval + "秒后重发"
            this.showDisabled = true
          }, 1000)
        } else {
          this.$message.error(res.msg)
        }
      })


    },
    initPhoneTipMsg(){
      this.clearSmsTimer()
      this.phoneTipMsg = "获取验证码"
      this.phoneCodeStatus = false
      this.smsCodeInterval = 60
      this.showDisabled = false
    },

    clearSmsTimer(){
      clearInterval(this.smsCodeTimer);
      this.smsCodeTimer = null;
    },
    //报名导入按钮
    async createExcel(){
      this.excelShow = true
    },
    //报名导入弹窗取消
    cancelexcel(){
      this.excelShow = false
      this.$refs.file.value = ""
      this.excelFile.file = ""
    },
    // 报名导入弹窗 确定按钮
    updateExcel(){
      if(!this.excelFile.file){
        return this.$message.warning("请选择上传文件")
      }
      let dataForm = new FormData()
      dataForm.append('file', this.excelFile.file)
      this.excelLoading = true
      createOrderByExcel(dataForm,this.loginUser.token).then((data) => {
        const { code, msg } = data
        const result = data.data
        if (code === 200) {
          if(result.failReasonList && result.failReasonList.length > 0){
            let tempStr = ""
            for (let i = 0; i < result.failReasonList.length; i++) {
              const el = result.failReasonList[i];
              if(i === 0){
                tempStr = el
              }else{
                tempStr += "<br/>"+el
              }
            }
            this.$message({
              message: tempStr,
              type: 'warning',
              duration:0,
              showClose:true,
              dangerouslyUseHTMLString: true,
            })
          }else{
            this.$message.success(msg+"。成功数："+result.successNum)
          }
          this.cancelexcel()
        } else {
          this.$message.error(msg)
        }
        this.excelLoading = false
      }).catch(() => {
        this.excelShow = false
        this.excelLoading = false
      })
    },
    // 第一次确定修改学习账号按钮
    changeSavestaudent() {
      console.log('this.student',this.formstudent);
      if(this.formstudent.newPhone && this.formstudent.newPhone == this.formstudent.oldPhone) {
        return this.$message.error('新的学习账号不能与旧账号相同！')
      }
      if(!this.formstudent.newPhone) {
        return this.$message.error('修改新的学习账号不能为空')
      }
      if(!this.formstudent.code) {
        return this.$message.error('验证码不能为空')
      }
      if(this.formstudent.code) {
        let params = {
          code: this.formstudent.code,
          phone: this.formstudent.newPhone
        }
        getverifyCode(params).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
          } else {
            console.log('验证成功');
            this.changeShowzx = true
          }
        })
      }
    },
    // 修改学习账号按钮
   async changeCustomerstudent() {
      
      this.formstudent.orderId = this.currCol.id
      this.formstudent.orderNo = this.currCol.orderNo
      const params = {
        orderId: this.currCol.id,
      }
      this.formstudent.reason = ''
      this.formstudent.code = ''
      this.formstudent.newPhone = ''
      const { data, code } = await this.$fetch("thrid_getOrderDetailInfo", params)
      const { usersDetails } = data
      this.formstudent.oldPhone = usersDetails.mobile
      this.formstudent.userId = usersDetails.userId
      this.changestudentShow = true
      console.log(' this.currCol', this.formstudent, data);
    },
    // 关闭请知晓按钮
    cancelstudentdelete() {
      this.sign = 0
      this.changeShowzx = false
      this.initPhoneTipMsg()
    },
    // 确认请知晓
    Savestaudent() {
      console.log('this.sign',this.sign);
      if(this.sign == 0 || this.sign == false) {
        this.$message.error('请勾选')
      } else {
        let params = {
          newPhone: this.formstudent.newPhone,
          oldPhone: this.formstudent.oldPhone,
          orderId: this.formstudent.orderId,
          reason: this.formstudent.reason,
          userId: this.formstudent.userId
        }
        console.log('params', params);
        updateLearningPhone(params).then(res => {
          if(res.code == 0) {
            this.$message.success(res.msg)
            this.changeShowzx = false
            this.changestudentShow = false
            this.getcustomerList();
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    // 关闭学习账号弹窗
    cancelstudent() {
      this.changestudentShow = false
    },
    // 复制号码
    seeShowClick(v) {
      let _this = this
      console.log('v.seeShow', v, v.mobilePhone);
      if(!v.seeShow) {
        getJudgetLookContack().then(async data => {
          if(data.result) {
            console.log('data.result', data.result);
            const params = {
              // detail: v.phone,
              operationType: 1,
              customerId: v.customerId,
              operationKey: 'getDetailByCustomerId'
            }
            if (v.type == 0) {
              params.operationType = 1
            }
            let res = await doSeeorderPhone(v.customerId,v.id)
            console.log('res.result', res);
            if(res.code === 0) {
              v.oldPhone = v.mobilePhone
              v.mobilePhone = res.result
              v.seeShow = !v.seeShow
              params.detail = res.result
              await doOperationSave(params)  
            }
            this.$forceUpdate()
            this.$copyText(v.mobilePhone).then(function () {
              console.log('执行到这里');
              _this.$message.success("已复制号码")
            }, function () {
              _this.$message.error("抱歉，复制号码失败")
            })
          } else {
            v.seeShow = false
            this.$forceUpdate()
            _this.$message.error("抱歉，复制号码失败")
          }
        })
      } else {
        v.mobilePhone = v.oldPhone
        delete v.oldPhone
        v.seeShow = !v.seeShow
        this.$forceUpdate()
      }
    },
    // 变更渠道名称
    changeCustomer() {
      console.log('this.currList', this.currList);
      this.reason = ''
      this.getrecruitName()
      getRecruitSpinner().then(data => {
        this.recruitChannelsselect = data.result
        this.form = {}
        this.changeShow = true
      })
    },
    // 获取渠道名称
    getrecruitName() {
      getorderName(this.currList.id).then(res => {
        if (res.code == 0) {
          this.recruitChanne = res.result ? res.result : {}
        }
      })
    },
    Changeselect(val) {
      if (val !== 1723) {
        this.form = {}
      } else {
        this.form.promotionForm = ''
        getflowPartnerList().then(data => this.partnerList = data.result)
      }
      this.form.crmChannelsTypeId = val
      console.log('val选择',val, this.form, this.form.crmChannelsTypeId);
      this.$forceUpdate()
    },
    promotionFormChange(val) {
      console.log('val促单',val);
      // this.form.promotionForm = val
    },
    // 变更渠道名称确定
    changeSave() {
      
      if (!this.form.crmChannelsTypeId) {
        this.$message.error('请选择变更渠道')
      } else if (!this.reason) {
        this.$message.error('请填写变更理由')
      } else if (this.form.crmChannelsTypeId == 1723 && this.form.economicFlowPartner && !this.form.promotionForm) {
        this.$message.error('请选择促单形式')
      } else {
        if (!this.form.flowPartner) {
          // this.form.economicFlowPartner = ''
          // this.form.economicFlowPartnerCode = ''
          // this.form.economicFlowPartnerPhone = ''
          this.form.flowPartner = ''
          this.form.flowPartnerCode = ''
          this.form.flowPartnerPhone = ''
        }
        let params = {
          orderId: this.currList.id,
          ...this.form,
          reason: this.reason
        }
        this.loadingsave = true
        getOrderSave(params).then(res => {
          if (res.code == 0) {
            this.changeShow = false
            this.$message.success('成功')
            this.reason = ''
            this.getcustomerList();
          } else {
            this.$message.error(res.msg)
          }
          this.loadingsave = false
        })
      }
      console.log('this.form', this.form);
    },
    partnerClick(val) {
      this.partnerList.map(item => {
          if(item.flowPartner && item.flowPartner === val) {
            this.form.flowPartnerCode = item.flowPartnerCode
            this.form.flowPartner = item.flowPartner
            this.form.flowPartnerPhone = item.flowPartnerPhone
            // this.form.economicFlowPartnerCode = item.economicFlowPartnerCode
            // this.form.economicFlowPartner = item.economicFlowPartner
            // this.form.economicFlowPartnerPhone = item.economicFlowPartnerPhone
          }
        })
    },
    cancel() {
        this.changeShow = false
    },
    // 获取一开始的下拉框来源
    async _getSearchAll() {
      const respone = await getResTeachList()
      this.userList = respone.result
      this.searchAllParam.map((item) => {
        
        if(item.name == "dealSaleDeptId") {
          // 获取成交部门树形结构
          reqworkbenchDeptTree().then((res) => {
              item.option = res.result
          })
        } else if(item.name == "channelCode") {
          getRecruitSpinner().then(data => item.option = data.result)
        } else if(item.name == "teacherSalesCrmId" || item.name == "teacherRecruitCrmId") {
          item.option = this.userList
        } else if(item.name === "productId") {
          this.$fetch("thrid_getAllProduct", { statusSchool: 1 }).then(data => item.option = data.data)
        } else if(item.name == "teacherRecommandNCid") {
          getTyUsertList().then(res => item.option = res.result.filter(item => item.ncId))
        } else if(item.name == 'dealDeptIdList') {
          getDeptList().then(res => item.option = res.result)
        } else if(item.name === "recruitChannels") {
          getRecruitSpinner().then(data => {
            item.option = data.result
          })
        } else if(item.name === "payModeList") {// 获取支付方式
          this.$fetch("queryByConfigureSettleMethodData",{manageSelectStatus: 1}).then(res => {
            item.option = res.data
          })
        }
      })
    },
    // 更新自定义查询
    searchList() {
      this.getSearchList()
    },
    // 更多查询条件
    searchMore(value) {
      this.searchParamList = value
        ? this.searchAllParam
        : this.defaultParamList;
      this.$nextTick(() => {
        this.topHeight = this.$refs.contentTop.offsetHeight + 40
      })
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {

      const {code, result } = await getUserCustomQuery('orderList');
      if(code === 0) {
        this.defaultParamList = ["name", "mobile", "orderStatus"]
        if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
          
          this.searchParamFromApi = JSON.parse(result.fieldData)
          
          this.defaultParamList = []
          this.searchParamFromApi.map(() => {
            this.defaultParamList.push("")
          })
          let index
          this.searchAllParam.filter((item) => {
            index = this.searchParamFromApi.findIndex(i => i === item.name)
            this.defaultParamList[index] = item
          });
        } else {
          this.defaultParamList = this.searchAllParam.filter((item) =>
            this.defaultParamList.includes(item.name)
          );
        }
        this.searchParamList = this.defaultParamList;
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 44
        })
      }
    },
    // 获取列表数据
    async getcustomerList() {
      this.listLoading = true;
      let form = {
        ...this.searchParam,
        ...this.pageParam
      }
      this.orderIds = []
      const { code, result } = await getOrderListPage(form)
      if( code == 0 ){
        result.list.map(item => {
          if(item.mobilePhone) {
            item.seeShow = false
          }
        })
        //1、更新分页数据
        
        //2、更新列表页面
        this.tableData  = result.list || [];
        let projectCodeMap = {
          "1":"财经项目",
          "2":"自考项目",
          "3":"财会项目",
          "4":"教师在线"
        }
        this.tableData.forEach( item=>{
            item.projectCodeName = projectCodeMap[item.trackType];
            this.$set(item, 'checked', false);
            item.checked = false
            if(item.signContractStatus == 26) {
              this.orderIds.push(item.id)
            }
        })
        this.orderId = ''
        this.total = result.total
        this.$nextTick(() => {
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      } else {
        this.tableData = []
        this.total = 0
      }
   
      this.listLoading = false;
    },
    // 单选事件
    clickRow(row) {
      this.currCol = row;
      this.orderId = row.id
      this.currList = row
      this.orderTypeset = row.orderType
      this.getTransactionInfo(row)
    },
    // 多选事件--
    selectionRow (val) {
      this.orderIds = []
      val = val.length ? val : this.tableData;
      val.map(item => {
        if(item.signContractStatus == 26) {
          this.orderIds.push(item.id)
        }
      })
    },
    changeCurrentRow( val, rowIndex, row) {
      if(row.checked) {
        const data = this.tableData
        //遍历表格的数据，将非本行的所有数据的checked属性置为false，即取消选择
        for(let index in data){
            if( index == rowIndex ){
                data[index].checked = val
            }else{
                data[index].checked = false
            }
        }
        this.tableData = data
        this.orderId = row.id
        this.getTransactionInfo(row)
      } else {
        this.isChange = false
        this.changeMsg = ""
        this.orderId = ""
      }
    },
    // 判断转校订单能否进行变更操作
    async getChangeOperation(params) {
      const { code, msg } = await getChangeOperation(params)
      if(code == -1) {
        this.isChange = true
        this.changeMsg = msg
      } else {
        this.isChange = false
      }
    },
         /***
    * 获取成交信息
    */
    getTransactionInfo(row){
      const form = {
        orderId: row.id
      }
      getTransactionInfoByOrderId(form).then( res=>{
        if( res.code === 0 ){
        
          let transactionInfo = res.result || {};
          this.transactionInfo = transactionInfo;
          Object.keys(this.transactionInfo).forEach( key=>{
              if( key == "isFourSix" || key == "isRecommand" ){
                this.transactionInfo[`${key}Name`] = this.transactionInfo[key] == 1 ? '是': '否'
              }
          })
          let result =  this.transactionInfo;
          console.log('变更', result);
            this.infoList.orderId = result.orderId
            this.infoList.customerId = result.customerId
            this.infoList.changeAfterCrmId = ""
            this.infoList.dealSchoolName = result.dealSchoolName
            this.infoList.ncDealSchoolId = result.ncDealSchoolId
            this.infoList.crmDealSchoolDeptId = result.crmDealSchoolDeptId
            this.infoList.orderStatus = row.orderStatus
            this.teacherType = {
              teacherSalesName: result.teacherSalesName,
              teacherRecommandName: result.teacherRecommandName,
              teacherRecruitName: result.teacherRecruitName
            }
            this.teacherIdType = {
              teacherSalesId: result.ncTeacherSalesUserId,
              teacherRecommandId: result.ncTeacherRecommandUserId,
              teacherRecruitId: result.ncTeacherRecruitUserId,
              crmTeacherRecruitUserId: result.crmTeacherRecruitUserId,
              crmTeacherSalesUserId: result.crmTeacherSalesUserId,
              crmTeacherRecommandUserId: result.crmTeacherRecommandUserId
            }
            // 转校的才需要判断
            // if(row.transferSchool) {
              console.log('this.loginUser', this.loginUser);
            const params = {
              crmCampusSchoolDeptId: result.crmCampusSchoolDeptId,
              deptId: this.loginUser.deptId,
              signSchoolName: row.signSchoolName, 
              transferSchool: row.transferSchool,
              crmDealSchoolDeptId: row.crmDealSchoolDeptId
            }
            this.getChangeOperation(params)
            // }

            // 根据nc校区获取招生老师
            console.log('row', row);
            const arr = row.crmDealSchoolDeptId? [row.crmDealSchoolDeptId, row.crmCampusSchoolDeptId]:[row.crmDealSchoolDeptId]
            GetuserExtend(arr).then(res => {
              console.log('变更招生老师', res);
              this.teacherList = res.result || []
              // this.saleTeacher = res.result || []
              // this.teacherList.map(item => {
              //   item.ncUserPk = item.pk
              // })
            }); 

            // 获取变更销售数据
            getFindUserExtendAll().then(res => {
              this.saleTeacher = res.result || []
              this.saleTeacher.map(item => {
                item.name = item.userName
              })
            })

            // 获取nc所有人员信息
            getRecommentUserList({limit: -1, page: 1}).then(res => {
              this.saleTransfer = res.data.result
              this.saleTransfer.map(item => {
                item.ncUserPk = item.ncId
                item.name = item.nickName
              })
              console.log('this.saleTransfe', this.saleTransfe);
            })
        }
      })
    },
    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getcustomerList()
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getcustomerList()
    },
    // 查询条件
    search() {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = 20
      this.searchParam = {}
      this.searchAllParam.forEach( item => {
          if( (item.value !== undefined && item.value !== null && item.value !== '' ) || ( Array.isArray(item.value)  && item.value.length ) ) {
              if(item.type === "date") {
                if(item.name === "createTime") {
                  this.searchParam.createTimeStart = item.value[0];
                  this.searchParam.createTimeEnd = item.value[1];
                }
                if(item.name === "firstDealTime") {
                  this.searchParam.firstCreateTimeStart = item.value[0];
                  this.searchParam.firstCreateTimeEnd = item.value[1];
                }
              } else {
                this.searchParam[item.name] = item.value 
              }
         }
      })

      this.getcustomerList()
    },
    // 重置
    reset() {
      this.searchParamList.map(item => {
        if(item.value || item.value == 0) {
          if(item.name === "createTime") {
            item.value = []
          } else {
            item.value = ""
          }
        }
      })

      this.pageParam = {
        pageNum: 1,
        pageSize: 20,
        total: 0,
        sortItems: [
          {
            asc: false,
            column: "createTime",
          },
        ],
      }
      this.orderId = ""
      this.isChange = false
      
      this.search()
    },

    /*********************** 页面跳转部分  *******************************/
    //跳转到详情页面
    toDetaiPage( dataItem ){  
      let path = `${dataItem.businessId}` === '1' ?  "/afterSale/cjOrderDetail": "/afterSale/xlOrderDetail";
      this.$router.push( {
        path: path,
        query: {
          orderId: dataItem.id,
          transferSchool: dataItem.transferSchool?1:0
        }
      });      
    },
    // 批量重推协议
    signClick() {
      this.$hqMessageBox({
        title: '提示',
        message:'确认重推协议吗?'
      }).then(() => {
        this.$fetch("doBatchRefresh", {}, {orderIds: this.orderIds.toString()}).then(res => {
          this.$message.success(res.data)
          this.getcustomerList()
        }).catch(res => {
          this.$message.error(res.msg)
        })
      }).catch(() => {
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.customer {
  // padding-bottom: 40px;
  .titie {
    text-align: left;
  }

  .tool {
    background: #f5f7f9;
  }

  .customer-tool {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;

    .tab-bar {
      overflow: hidden;

      .active {
        color: #409eff;
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: #337aff;
          bottom: 0;
          left: 0;
        }
      }

      > span {
        float: left;
        margin-right: 12px;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        color: #000;
        cursor: pointer;
        font-size: 14px;
        padding: 0 10px;
      }
    }
  }
  .phone {
    .el-button--text {
      padding: 10px 4px;
    }
  }
}
</style>
