var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-button",
        {
          staticClass: "hq-button",
          attrs: { disabled: _vm.disabled, type: "primary" },
          on: {
            click: function($event) {
              _vm.modalShow = true
            }
          }
        },
        [_vm._v(_vm._s("变更" + _vm.title.replace("老师", "")))]
      ),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.modalShow, title: _vm.title, width: "400" },
          on: { "on-cancel": _vm.cancel }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.formRules } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "changeResson" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              height: 80,
                              placeholder: "输入变更理由，200字内",
                              name: "input-7-4",
                              label: "变更理由"
                            },
                            model: {
                              value: _vm.form.changeResson,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "changeResson", $$v)
                              },
                              expression: "form.changeResson"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 24 }
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "原" + _vm.title,
                              "hide-details": "auto",
                              outlined: "",
                              readonly: "",
                              dense: true,
                              height: 32
                            },
                            model: {
                              value: _vm.changeBeforeName,
                              callback: function($$v) {
                                _vm.changeBeforeName = $$v
                              },
                              expression: "changeBeforeName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.teacherList,
                              "menu-props": { bottom: true, offsetY: true },
                              label: "变更为",
                              "item-text": "userName",
                              "item-value": "ncUserPk",
                              outlined: "",
                              dense: true,
                              height: 32,
                              clearable: true,
                              "clear-icon": "$clear",
                              "hide-details": "auto",
                              "no-data-text": "暂无数据"
                            },
                            on: { change: _vm.teacherChange },
                            model: {
                              value: _vm.form.changeAfterNcId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "changeAfterNcId", $$v)
                              },
                              expression: "form.changeAfterNcId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }