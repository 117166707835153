<!--
 * @Description: 变更组件
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-07-28 20:10:02
 * @LastEditors: Seven
 * @LastEditTime: 2022-06-29 15:40:30
-->
<template>
  <span>
    <el-button :disabled="disabled" class="hq-button" @click="modalShow = true" type="primary">{{'变更' + title.replace('老师', '')}}</el-button>
    <HqModal :visible="modalShow" :title="title" width="400" @on-cancel="cancel">
      <el-form ref="form" :model="form" :rules="formRules">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item prop="changeResson">
              <v-textarea
                v-model="form.changeResson"
                outlined
                :height="80"
                placeholder="输入变更理由，200字内"
                name="input-7-4"
                label="变更理由"
              ></v-textarea>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="margin-top: 10px">
            <el-form-item>
              <v-text-field
                v-model="changeBeforeName"
                :label="'原' + title"
                hide-details="auto"
                outlined
                readonly
                :dense="true"
                :height="32"
              ></v-text-field>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <v-autocomplete
                v-model="form.changeAfterNcId"
                :items="teacherList"
                :menu-props="{ bottom: true, offsetY: true }"
                label="变更为"
                item-text="userName"
                item-value="ncUserPk"
                outlined
                :dense="true"
                :height="32"
                :clearable="true"
                clear-icon="$clear"
                hide-details="auto"
                no-data-text="暂无数据"
                @change="teacherChange"
              ></v-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="save" type="primary">确认</el-button>
      </div>
    </HqModal>
  </span>
</template>
<script>
import { doOrderUpdateOrderDetail } from "api/order/change"
export default {
  name: "changeModal",
  props: {
    title: {
      type: String,
    },
    teacherList: {
      type: Array,
    },
    infoList: {
      type: Object,
    },
    changeType: {
      type: String,
    },
    changeBeforeName: {
      type: String,
    },
    changeBeforeNcId: {
      type: String,
    },
    method: {
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    orderTypeset: {
      type: [String, Number]
    }
  },
  // {"changeResson":"测试","changeAfterNcId":"0001A51000000008B8RM","changeAfterName":"程戎","changeType":"0","changeBeforeName":"张慧飞财经军团长","orderId":"1428905495393079296","customerId":"29809888","changeAfterCrmId":"","changeBeforeCrmId":"27752","dealSchoolName":"许昌大商校区","ncDealSchoolId":"0001A5100000000043HD","crmDealSchoolDeptId":"2799"}
  
  data() {
     const validateReason = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入变更理由'));
        } else {
          if (value.length > 200) {
            callback(new Error('变更理由输入长度不能大于200'));
          }
          callback();
        }
      };
    return{
      form: {
        changeResson: "",
        changeAfterNcId: null,
      },
      formRules: {
        changeResson: [
          {required: true, validator: validateReason}
        ],
        changeAfterNcId: [
          {required: true, message: "请选择变更人"}
        ]
      },
      modalShow: false,
    }
  },
  methods: {
    teacherChange(value) {
      console.log('value', value);
      if(value) {
        this.teacherList.map(item => {
          if(value == item.ncUserPk) {
            console.log( value == item.ncUserPk, item.ncUserPk);
            console.log('item',item);
            this.form.changeAfterName = item.userName
          }
        })
      } else {
        this.form.changeAfterName = ""
      }
      console.log('this.form.changeAfterName', this.form.changeAfterName);
    },
    save() {
      this.$refs['form'].validate((valid) => {
        if(valid) {
          this.form.changeType = Number(this.changeType)
          this.form.orderType = this.orderTypeset
          this.form.changeBeforeName = this.changeBeforeName
          doOrderUpdateOrderDetail({...this.form, ...this.infoList, ...{changeBeforeNcId: this.changeBeforeNcId}}).then(data => {
            if(data.code === 0) {
              this.$message.success(data.msg)  
              this.method()
            } else {
              this.$message.error(data.msg)
            }
            this.cancel()  
          })
        }
      })
    },
    cancel() {
      this.modalShow = false
      this.form = {
        changeResson: "",
        changeAfterNcId: null
      }
    }
  }
}
</script>
